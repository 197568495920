import React, { useState } from "react";
import clsx from "clsx";
import { Text, Container } from "@atoms";
import { ProgramCard, Carousel } from "@molecules";
import PatternTop from "@svg/HorizontalPatternTop";
import PatternBottom from "@svg/HorizontalPatternBottom";

const ProgramsOverview = ({ programs }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = programs?.map(program => program?.heading);

  return (
    <div className="my-10 sm:my-20 lg:my-32">
      <Container>
        <div className="flex flex-col overflow-hidden rounded-3xl bg-yellow md:flex-row">
          <div className="relative z-10 hidden w-full max-w-none flex-col divide-y-[1px] divide-white border-b border-white md:flex md:max-w-[8rem] md:border-r">
            {tabs?.map((tab, i) => {
              return (
                <button
                  key={`programs-${tab}`}
                  onClick={() => setActiveTab(i)}
                  type="button"
                  className={clsx(
                    "h-full w-full py-4 px-4 text-center transition duration-200 sm:py-8 md:py-16",
                    {
                      "bg-yellow text-purple": activeTab === i,
                      "bg-purple text-white hover:bg-yellow hover:text-purple":
                        activeTab !== i,
                    }
                  )}
                >
                  <Text variant="body" className="font-bold !leading-none">
                    {tab}
                  </Text>
                </button>
              );
            })}
          </div>
          <div className="relative w-full py-8 md:py-0">
            <PatternTop className="pointer-events-none absolute -top-40 -left-1 w-80 text-white md:w-[27rem]" />
            <div className="hidden md:block">
              <ProgramCard {...programs[activeTab]} />
            </div>
            <div className="block md:hidden">
              <Carousel
                indicators
                nextButtonClass="-translate-x-6"
                prevButtonClass="translate-x-6"
              >
                {programs.map((slide, i) => {
                  return <ProgramCard {...slide} />;
                })}
              </Carousel>
            </div>
            <PatternBottom className="pointer-events-none absolute -bottom-28 -right-44 w-96 text-white md:bottom-[-8.75rem] md:w-[32rem]" />
          </div>
        </div>
      </Container>
    </div>
  );
};

ProgramsOverview.defaultProps = {};

export default ProgramsOverview;
